import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import store from "./redux/store/index";
import Routes from "./routes/Routes";

import "./App.css";
import { InterceptadorApi } from "./services/api";

const App = () => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const splash = document.querySelector(".splash");
		if (splash) {
		splash.classList.remove("active");
		}
		setIsLoading(false);
	}, []);

	if (isLoading) {
		return <div>Carregando..</div>
	}

	return (<>
        <InterceptadorApi/>
		<Provider store={store}>
			<Routes />
			<ReduxToastr timeOut={5000} newestOnTop={true} position="top-right" transitionIn="fadeIn" transitionOut="fadeOut" progressBar closeOnToastrClick />
		</Provider>
    </>);
};

export default App;
