import React, { useState } from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import "./Navbar.css";
import { Collapse, Navbar, Nav } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

const NavbarToggle = connect((store) => ({
	app: store.app,
}))(({ dispatch }) => {
	const [clickAngle, setClickAngle] = useState(true);

	return (
		<span
			className="sidebar-toggle d-flex mr-2"
			onClick={() => {
				dispatch(toggleSidebar());
				setClickAngle(!clickAngle);
			}}
		>
			{clickAngle ? (
				<FontAwesomeIcon icon={faAngleDoubleLeft} className="fas fa-angle-double-right fa-2x" />
			) : (
				<FontAwesomeIcon icon={faAngleDoubleRight} className="fas fa-angle-double-left fa-2x" />
			)}
		</span>
	);
});

const obterDataAtual = () => {
	const data_hj = new Date(Date.now());

	const dia = String(data_hj.getDate()).padStart(2, "0");
	const mes = String(data_hj.getMonth() + 1).padStart(2, "0");
	const ano = data_hj.getFullYear();
	const hora = String(data_hj.getHours()).padStart(2, "0");
	const min = String(data_hj.getMinutes()).padStart(2, "0");
	const seg = String(data_hj.getSeconds()).padStart(2, "0");

	const data_atual = dia + "/" + mes + "/" + ano + " " + hora + ":" + min + ":" + seg;

	return data_atual || "";
}

const Header = connect((store) => ({
	sidebar: store.sidebar,
}))(({ sidebar }) => {
	const [dataContinua, setDataContinua] = useState(obterDataAtual());
	
	setInterval(() => {
		setDataContinua(obterDataAtual());
	}, 1000);

	let leftContentJsx = <NavbarToggle/>;
	let centerContentJsx = <center> <div className="lead">Segurança e Medicina do Trabalho</div> </center>;
	let rightContentJsx =
		<div style={{ paddingTop: "1rem", paddingRight: "1rem" }}>
			<h4 className="card-subtitle text-muted">{dataContinua}</h4>
		</div>;

	if (sidebar.isOnRight) {
		leftContentJsx =
			<div style={{ paddingTop: "1rem", paddingRight: "1rem" }}>
				<h4 className="card-subtitle text-muted">{dataContinua}</h4>
			</div>;
		rightContentJsx = <NavbarToggle/>;
	}

	return (
		<Collapse navbar>
			<div className="lead" style={{display: "flex", flex: 1, justifyContent: "left"}}>
				<Nav> {leftContentJsx} </Nav>
			</div>
			
			<div className="lead" style={{display: "flex", flex: 1, justifyContent: "center"}}>
				<Nav> {centerContentJsx} </Nav>
			</div>

			<div className="lead" style={{display: "flex", flex: 1, justifyContent: "right"}}>
				<Nav> {rightContentJsx} </Nav>
			</div>
		</Collapse>
	);
});

const NavbarComponent = ({ sidebar }) => {
	return (
		<Navbar expand className="navbar-theme">
			<React.Fragment>
				<Header />
			</React.Fragment>
		</Navbar>
	);
};

export default connect((store) => ({
	sidebar: store.sidebar,
}))(NavbarComponent);
