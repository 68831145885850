import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { getRoutes } from "./index";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import RotaPrivada from "./PrivateRoute";
import SignIn from "../pages/auth/SignIn";
import Sidebar from "../components/Sidebar";
import Wrapper from "../components/Wrapper";
import Navbar from "../components/Navbar";
import Main from "../components/Main";
import Footer from "../components/Footer";

const routesWithWrapper = (routes) => {

	const rotas = routes.map(({ children, path, component: Component }, index) => {
		// se for pai, retorna filhos
		if (children) {
			return children.map(({ path, component: Component }, index) => (
				<RotaPrivada
					key={path + index}
					path={path}
					exact
					render={(props) => (
						<div className="content">
							{Component ?
								<Component {...props} />
								: null}
						</div>
					)}
				/>
			))
		}
		// caso contrário, retorna pai
		else {
			return (
				<RotaPrivada
					key={path + index}
					path={path}
					exact
					render={(props) => (
						<div className="content">
							{Component ?
								<Component {...props} />
								: null}
						</div>
					)}
				/>
			)
		}
	});

	return (<>
		<Wrapper>
			<Sidebar />
			<Main>
				<Navbar />
				{rotas}
				<Footer />
			</Main>
		</Wrapper>
	</>);
}

const Routes = () => {

	const loginPath = "/login";

	const [sidebarRoutes, setSidebarRoutes] = useState([]);

	useEffect(() => {
		if (window.location.pathname !== loginPath) {
			const fetchRoutes = async () => {
				const routes = await getRoutes();
				setSidebarRoutes(routes);
			};
			fetchRoutes();
		}
	}, []);

	return (<>
		<BrowserRouter>
			<Switch>
				<Route component={SignIn} path={loginPath} />
				{routesWithWrapper(sidebarRoutes)}
				<RotaPrivada
					obterRotas={() => {}}
					render={() => (
						<AuthLayout>
							<Page404 />
						</AuthLayout>
					)}
				/>
			</Switch>
		</BrowserRouter>
	</>);
};

export default Routes;
