import React, { useState } from "react";
import axios from "axios";
import ModalConfirmacao from "../modals/ModalConfirmacao";

const API_DOMINIO = process.env.REACT_APP_API_DOMINIO;
const API_PORTA = process.env.REACT_APP_API_PORTA;
const API_PROTOCOLO = process.env.REACT_APP_API_PROTOCOLO;

export const ROTAS = {
	OBTER_ROTAS_DO_BANCO: "obter_rotas",

	USUARIO_LOGIN: "login",
	USUARIOS_OBTER: "obter_usuarios",
	USUARIO_CADASTRAR: "usuario_incluir",
	USUARIO_ALTERAR: "usuario_alterar",

	FUNCIONARIOS_OBTER: "obter_funcionarios",
	FUNCIONARIOS_ATUALIZAR_SOC: "atualizar_soc_funcionario",

	KPIS_OBTER: "obter_kpis",
};

export const localStorageStrings = {
	PATH_ROUTE: "path-route",
	APP_USER: "app-user",
	APP_DEPARTAMENTO: "app-departamento",
	APP_PERMISSOES: "app-permissoes",
	APP_DATAPUSH: "app-datapush",
};

export const sessionStorageStrings = {
	DADOS_USUARIO_LOGADO: "dados-usuario-logado",
	OPEN_ROUTES: "open-routes",
	ROUTES: "routes",
	SCROLL_POSITION: "scroll-position",
	USER_PATH_URL_BEFORE_LOGIN: "user-path-url-before-login",
};

const headers = {
	"Content-Type": "application/json",
	Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
	token: JSON.parse(sessionStorage.getItem(sessionStorageStrings.DADOS_USUARIO_LOGADO))?.token || "",
};

// const hostname = window.location.hostname;
// const dominio_hostname = API_PROTOCOLO + API_DOMINIO : API_PROTOCOLO + hostname;
const dominio_hostname = API_PROTOCOLO + API_DOMINIO;

export const API = axios.create({
	baseURL: dominio_hostname + ":" + API_PORTA,
	headers: headers,
});

export const InterceptadorApi = () => {
	const [mostrar, setMostrar] = useState(false);

	const sair = () => {
		sessionStorage.removeItem(sessionStorageStrings.ROUTES);
		sessionStorage.removeItem(sessionStorageStrings.OPEN_ROUTES);
		sessionStorage.removeItem(sessionStorageStrings.DADOS_USUARIO_LOGADO);
		window.location.href = "/login";
	};

	// interceptará a resposta e verificará se o erro é 3003,
	// sendo assim, desloga usuário quando o mesmo clicar o botão
	API.interceptors.response.use(
		(response) => response,
		(error) => {
			if (window.location.pathname !== "/login" && error?.response?.data?.erro_codigo === 3003) {
				sessionStorage.setItem(sessionStorageStrings.USER_PATH_URL_BEFORE_LOGIN, window.location.pathname);
				// mostrarToastr({ mensagem: "Sessão expirada, faça login novamente", tipo: TOASTR_STRINGS.WARNING.TIPO, duracao: 0 });
				setMostrar(true);
			}
			return Promise.reject(error);
		}
	);

	return (
		<ModalConfirmacao
			textoTitulo={"Sessão expirada"}
			textoDescritivo={"Por favor, faça login novamente"}
			mostrar={mostrar}
			setMostrar={() => setMostrar(false)}
			callbackSeSim={sair}
			forcarUsuario={true}
		/>
	);
};
