import React, { useState } from "react";
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row } from "reactstrap";
import { OGSpinner } from "../helpers/utils";

const ModalConfirmacao = ({
    callbackSeSim, // callback da ação, que será executada quando o usuário confirmar
    mostrar, // variável setState bool para mostrar/esconder o modal
    setMostrar, // função setState para mudar a variável `mostrar`
    textoTitulo = null, // título
    textoDescritivo = null, // texto / pergunta descrevendo a ação
    mostrarBotaoNao = false, // mostra o botão não como opção
    forcarUsuario = false // força usuário a escolher uma das opções, sem poder fechar o modal até escolher
}) => {
    const [carregando, setCarregando] = useState(false);

    const nao = () => { setMostrar(false); };

    const sim = async () => {
        setCarregando(true);
        await callbackSeSim();
        setCarregando(false);
        setMostrar(false);
    };

    const jsxBotoes = (<>

        <Row className="row-btn-col2">
            
            {mostrarBotaoNao ?
            <Col>
                <Button color="danger" className="inline-btn" size="md" onClick={nao}>
                    Não
                </Button>
            </Col>
            : null}

            <Col>
                <Button color="success" className="inline-btn" size="md" onClick={sim}>
                    {mostrarBotaoNao ? "Sim" : "OK"}
                </Button>
            </Col>

        </Row>
    </>);

    return (
        <Container fluid>
            <Modal isOpen={mostrar} toggle={carregando || forcarUsuario ? null : nao} size="sm">
                <ModalHeader toggle={carregando || forcarUsuario ? null : nao} />
                <ModalBody className="text-center">
                    {textoTitulo ? <h4>{textoTitulo}</h4> : null}
                    {textoDescritivo ? <p>{textoDescritivo}</p> : <br/>}
                    <center>
                        {carregando ? <OGSpinner /> : jsxBotoes}
                    </center>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>
        </Container>
    );
};

export default ModalConfirmacao;
