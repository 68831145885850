import React, { useState } from "react";
import cliente_logo from "../../assets/img/logos/QS_icone_logo-01.png";
import { Button, Card, CardBody, FormGroup, Label, Row, Col, Container } from "reactstrap";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import packageJson from "../../../package.json";
import { useHistory } from 'react-router-dom';
import "./Login.css";
import { API, REQ_API, ROTAS, sessionStorageStrings }  from "../../services/api";
import { OGSpinner, showToastr } from "../../helpers/utils";

const SignIn = () => {
	const [isLoading, setLoading] = useState(false);
	const [erroMsg, setErroMsg] = useState(null);
	
	const history = useHistory();
	const API_PROTOCOLO = process.env.REACT_APP_API_PROTOCOLO;
	console.log(API_PROTOCOLO+"fffffffffff");

	const handleSubmit = async (values) => {

		setLoading(true);
		
		const user_path_url_before_login = sessionStorage.getItem(sessionStorageStrings.USER_PATH_URL_BEFORE_LOGIN);
		
        await API.post(ROTAS.USUARIO_LOGIN, values)
        .then(res => {
            sessionStorage.setItem(sessionStorageStrings.DADOS_USUARIO_LOGADO, JSON.stringify(res?.data?.retorno));
            history.push(user_path_url_before_login !== null && user_path_url_before_login !== "/login" ? user_path_url_before_login : "/");
            sessionStorage.removeItem(sessionStorageStrings.USER_PATH_URL_BEFORE_LOGIN);
            history.go(0);
        })
        .catch(error => {
            setErroMsg(error?.response?.data?.erro);
        });
		
		setLoading(false);
	};

	const validataions = yup.object().shape({
		//email: yup.string().required(),
		pwd: yup.string().min(4, "Senha deve conter no minímo 4 digitos.").required("A senha é obrigatória."),
	});

	return (
		<React.Fragment>
			<div className="text-center mt-4">
				<h2>
					<br />
					Qualy Service
				</h2>
				<div className="lead">Segurança e Medicina do Trabalho</div>
				<br/>
			</div>
			{/* <div className="bodyHome"> */}
			<Container>
				<Row className="d-flex align-items-center justify-content-center">
					<Col xs={10} sm={10} md={8} lg={6}>
						<Card className="CardLogin">
							<CardBody>
								<div className="m-sm-4">
									<div className="text-center">
										<img src={cliente_logo} alt="Logo" className="img-fluid" width="250" height="250" />
									</div>
									<Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={validataions}>
										<Form>
											<FormGroup>
												<Label>CPF</Label>
												<Field
													name="user"
													className="form-control form-control-lg"
													placeholder="Insira seu CPF"
												/>
												<ErrorMessage component="span" name="user" className="LoginErro" />
											</FormGroup>
											<FormGroup>
												<Label>Senha</Label>
												<Field
													name="pwd"
													type="password"
													className="form-control form-control-lg"
													placeholder="Insira sua senha"
												/>
												<ErrorMessage component="span" name="pwd" className="LoginErro" />
											</FormGroup>
											<div></div>
											<div className="text-center mt-3">
												{erroMsg ? (
													<center>
														<span className="alert-msg"> {erroMsg} </span>
													</center>
												) : null}
												{isLoading ? <OGSpinner/> :
												<Button color="success" size="lg" type="submit" name="login" disabled={isLoading}>
													Entrar
												</Button>}
											</div>
										</Form>
									</Formik>
								</div>
							</CardBody>
						</Card>
						<div className="footerLogin">v{packageJson.version}</div>
					</Col>
				</Row>
			</Container>
			{/* </div> */}
		</React.Fragment>
	);
};

export default SignIn;
