import React, { useState } from "react";
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader, Label, FormGroup, Col, Row } from "reactstrap";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { API, ROTAS, sessionStorageStrings }  from "../../services/api";
import { mostrarToastr, OGSpinner, TOASTR_STRINGS } from "../../helpers/utils";

const ModalAlterarSenha = ({ setShowSenha, showSenha, usuarioId = null }) => {
	const [carregando, setCarregando] = useState(false);
	const handleFecharModal = () => { setShowSenha(false); };

	const data = JSON.parse(sessionStorage.getItem(sessionStorageStrings.DADOS_USUARIO_LOGADO));

	const handleAlterarSenha = async (values) => {
		setCarregando(true);

		const dadosUsuario = JSON.parse(sessionStorage.getItem(sessionStorageStrings.DADOS_USUARIO_LOGADO));
		const usuarioIdParaPayload = usuarioId || dadosUsuario?.id;

		const valuesBody = {
			usuario_id: usuarioIdParaPayload,
			alterar_senha: 0,
			senha: values.senha,
		};

		if (values.senha === values.confirmaSenha) {
			await API.post(ROTAS.USUARIO_ALTERAR, valuesBody)
			.then( res => {
				mostrarToastr({
					tipo: TOASTR_STRINGS.SUCCESS.TIPO,
					mensagem: "Senha alterada"
				});
				
				let dadosUsuarioLogado = JSON.parse(sessionStorage.getItem(sessionStorageStrings.DADOS_USUARIO_LOGADO));
	
				if (dadosUsuarioLogado && dadosUsuarioLogado.alterar_senha == 1) {
					dadosUsuarioLogado.alterar_senha = 0;
					sessionStorage.setItem(sessionStorageStrings.DADOS_USUARIO_LOGADO, JSON.stringify(dadosUsuarioLogado));
				}
				
				setShowSenha(false);
			}).catch(error => {
				mostrarToastr({
					tipo: TOASTR_STRINGS.ERROR.TIPO,
					mensagem: error?.response?.data?.erro || TOASTR_STRINGS.ERROR.MENSAGEM
				});
			});
			
		} else {
			mostrarToastr({
				tipo: TOASTR_STRINGS.ERROR.TIPO,
				mensagem: "As senhas tem que ser iguais!"
			});
		}
		setCarregando(false);
	};

	const validationYup = yup.object().shape({
		senha: yup.string().min(4, "Senha deve conter no minímo 4 digitos.").required("Campo Senha é obrigatório."),
		confirmaSenha: yup.string().oneOf([yup.ref("senha"), null], "Senha deve ser igual."),
	});

	const deveAlterarSennha = data?.alterar_senha && data.alterar_senha === 1;

	return (
		<Container fluid>
			<Modal isOpen={showSenha} toggle={deveAlterarSennha ? null : carregando ? null : handleFecharModal} size="sm">
				<ModalHeader toggle={deveAlterarSennha ? null : carregando ? null : handleFecharModal} />
				<ModalBody className="text-center">
					<h4>Alterar Senha</h4>
					<br />
					<Formik
						initialValues={{ senha: "", confirmaSenha: "" }}
						onSubmit={handleAlterarSenha}
						validationSchema={validationYup}
					>
						<Form>
							<div className="formSenha">
								<FormGroup>
									<Label className="novaSenha">Nova Senha</Label>
									<Field name="senha" className="form-control form-control-md nova-senha" type="password" placeholder="Insira uma nova senha" bsSize="md" />
									<ErrorMessage component="span" name="senha" className="LoginErro" />
								</FormGroup>
								<FormGroup>
									<Label className="novaSenha">Confirme a Senha</Label>
									<Field
										name="confirmaSenha"
										className="form-control form-control-md nova-senha"
										type="password"
										placeholder="Confirme a nova senha"
										bsSize="md"
									/>
									<ErrorMessage component="span" name="confirmaSenha" className="LoginErro" />
								</FormGroup>
							</div>
							<br />
							<center>
								{carregando ? <OGSpinner/> :
									<Row className="row-btn-col2">
										<Col>
											<Button color="danger" className="inline-btn" size="md" onClick={deveAlterarSennha ? null : handleFecharModal}>
												Não
											</Button>
										</Col>
										<Col>
											<Button color="success" className="inline-btn" size="md">
												Sim
											</Button>
										</Col>
									</Row>
								}
							</center>
						</Form>
					</Formik>
				</ModalBody>
				<ModalFooter></ModalFooter>
			</Modal>
		</Container>
	);
};

export default ModalAlterarSenha;
