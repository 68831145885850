import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => (
	<footer className="footer">
		<Container fluid>
			<Row className="text-muted">
				<Col xs={12} className="text-center">
					<p className="mb-0">&copy; {new Date().getFullYear()} - Qualy Service</p>
				</Col>
			</Row>
		</Container>
	</footer>
);

export default Footer;
