import { toastr } from "react-redux-toastr";
import { Spinner } from "reactstrap";
import React from "react";

export const statusCiProgramado = "Programado";

export function showToastr(props) {
	const options = {
		timeOut: parseInt(props.timeOut),
		showCloseButton: true,
		progressBar: true,
		position: "top-center",
	};

	const toastrInstance =
		props.typeBox === "info" ? toastr.info : props.typeBox === "warning" ? toastr.warning : props.typeBox === "error" ? toastr.error : toastr.success;

	toastrInstance(props.titleBox, props.messageBox, options);
}

export const TOASTR_STRINGS = {
	SUCCESS: {
		TIPO: "success",
		TITULO: "SUCESSO",
		MENSAGEM: "Sucesso",
	},
	INFO: {
		TIPO: "info",
		TITULO: "INFO",
		MENSAGEM: "Informação sem descrição",
	},
	WARNING: {
		TIPO: "warning",
		TITULO: "ATENÇÃO",
		MENSAGEM: "Aviso sem descrição",
	},
	ERROR: {
		TIPO: "error",
		TITULO: "ERRO",
		MENSAGEM: "Um erro inesperado ocorreu. Entre em contato com a TI",
	}
}

export function mostrarToastr({
	titulo = "",
	mensagem = "",
	tipo = "",
	duracao = 3000,
}) {

	if (tipo === "") {
		tipo = TOASTR_STRINGS.INFO.TIPO;
	}

	let toastrInstance = () => {};
	if (tipo === TOASTR_STRINGS.SUCCESS.TIPO){
		if(titulo === "") titulo = TOASTR_STRINGS.SUCCESS.TITULO;
		if(mensagem === "") mensagem = TOASTR_STRINGS.SUCCESS.MENSAGEM;
		toastrInstance = toastr.success;
	}
	else if (tipo === TOASTR_STRINGS.INFO.TIPO){
		if(titulo === "") titulo = TOASTR_STRINGS.INFO.TITULO;
		if(mensagem === "") mensagem = TOASTR_STRINGS.INFO.MENSAGEM;
		toastrInstance = toastr.info;
	}
	else if (tipo === TOASTR_STRINGS.WARNING.TIPO){
		if(titulo === "") titulo = TOASTR_STRINGS.WARNING.TITULO;
		if(mensagem === "") mensagem = TOASTR_STRINGS.WARNING.MENSAGEM;
		toastrInstance = toastr.warning;
	}
	else if (tipo === TOASTR_STRINGS.ERROR.TIPO){
		if(titulo === "") titulo = TOASTR_STRINGS.ERROR.TITULO;
		if(mensagem === "") mensagem = TOASTR_STRINGS.ERROR.MENSAGEM;
		toastrInstance = toastr.error;
	}

	const options = {
		timeOut: parseInt(duracao),
		showCloseButton: true,
		progressBar: true,
		position: "top-center",
	}

	toastrInstance(titulo, mensagem, options);
}

export const OGSpinner = () => {
	return ( <center><Spinner color="primary" className="mr-2" /></center> );
}

// Converte data no formato
export function rfc822ToDate(rfc822String) {
	// Parse the RFC 822 date string into a Date object
	const date = new Date(rfc822String);

	// Check if the date is valid
	if (isNaN(date)) {
		return "Invalid Date";
	}

	if (date == null) {
		return null;
	}

	// Extract day, month, and year components
	const day = date.getUTCDate().toString().padStart(2, "0");
	const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
	const year = date.getUTCFullYear();

	return `${year}-${month}-${day}`;
}

// Converte data no formato
export function convertDateFormat(inputDate) {
	if (inputDate == null) {
		return null;
	}

	// Assuming inputDate is in the format "YYYY-MM-DD"
	const dateArray = inputDate.split("-");
	const year = dateArray[0];
	const month = dateArray[1];
	const day = dateArray[2];

	// Creating a new Date object with the provided year, month, and day
	const convertedDate = new Date(year, month - 1, day);

	// Using toLocaleDateString to format the date as "DD/MM/YYYY"
	const formattedDate = convertedDate.toLocaleDateString("en-GB"); // 'en-GB' for DD/MM/YYYY format

	return formattedDate;
}

export const formatarCPF = (cpf) => {
	if (!cpf) return "";
	return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};