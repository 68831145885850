import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faKey } from "@fortawesome/free-solid-svg-icons";
import { getRoutes } from "../routes/index";
import cliente_logo from "../assets/img/logos/QS_icone_logo-01.png";
import cliente_foto from "../assets/img/logos/qls_logo.png";
import "./Sidebar.css";

import {
    Badge,
    Collapse,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    Col,
} from "reactstrap";
import { localStorageStrings, sessionStorageStrings } from "../services/api";
import ModalAlterarSenha from "../pages/admin/ModalAlterarSenha";
import ModalConfirmacao from "../modals/ModalConfirmacao";

const initOpenRoutes = (location) => {
    /* Open collapsed element that matches current url */
    let openRoutes = JSON.parse(sessionStorage.getItem(sessionStorageStrings.OPEN_ROUTES));

    if (openRoutes) return openRoutes;

    const routes = JSON.parse(sessionStorage.getItem(sessionStorageStrings.ROUTES));

    if (!routes) return {}; // Return an empty object if routes are not available yet

    openRoutes = {};
    routes.forEach((route, index) => {
        const isActive = location.pathname.indexOf(route.path) === 0;
        openRoutes[index] = isActive;
    });

    return openRoutes;
};

const SidebarCategory = withRouter(({ name, badgeColor, badgeText, icon, isOpen, children, onClick, location, to }) => {
    const getSidebarItemClass = (path) => {
        return location.pathname.indexOf(path) !== -1 || (location.pathname === "/" && path === "/login") ? "active" : "";
    };
    return (
        <li className={"sidebar-item " + getSidebarItemClass(to)}>
            <span data-toggle="collapse" className={"sidebar-link " + (!isOpen ? "collapsed" : "")} onClick={onClick} aria-expanded={isOpen ? "true" : "false"}>
                <FontAwesomeIcon icon={icon} fixedWidth className="align-middle mr-2" /> <span className="align-middle">{name}</span>
                {badgeColor && badgeText ? (
                    <Badge color={badgeColor} size={18} pill className="sidebar-badge">
                        {badgeText}
                    </Badge>
                ) : null}
            </span>
            <Collapse isOpen={isOpen}>
                <ul id="item" className={"sidebar-dropdown list-unstyled"}>
                    {children}
                </ul>
            </Collapse>
        </li>
    );
});

const SidebarItem = withRouter(({ id, name, badgeColor, badgeText, icon, location, to }) => {
    const getSidebarItemClass = (path) => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <li className={"sidebar-item " + getSidebarItemClass(to)}>
            {to !== "/header" ? (
                <NavLink id={id} to={to} className="sidebar-link" activeClassName="active">
                    {icon ? (
                        <React.Fragment>
                            <FontAwesomeIcon icon={icon} fixedWidth className="align-middle mr-2" /> <span className="align-middle">{name}</span>
                        </React.Fragment>
                    ) : (
                        name
                    )}{" "}
                    {badgeColor && badgeText ? (
                        <Badge color={badgeColor} size={18} pill className="sidebar-badge">
                            {badgeText}
                        </Badge>
                    ) : null}
                </NavLink>
            ) : null}
        </li>
    );
});

const Sidebar = ({ location, sidebar }) => {
    const pathLocation = useLocation();
    const dadosUsuarioLogado = JSON.parse(sessionStorage.getItem(sessionStorageStrings.DADOS_USUARIO_LOGADO));
    localStorage.setItem(localStorageStrings.PATH_ROUTE, pathLocation.pathname);

    if (!dadosUsuarioLogado) window.location = "/login";

    const [routes, setRoutes] = useState([]);
    const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));
    const [show, setShow] = useState(false);
    const [showSenha, setShowSenha] = useState(dadosUsuarioLogado?.alterar_senha && dadosUsuarioLogado.alterar_senha === 1 ? true : false);

    const history = useHistory();

    useEffect(() => {
        if (routes.length > 0) {
            setOpenRoutes(initOpenRoutes(location));
        }
    }, [routes, location]);

    useEffect(() => {
        const loadRoutes = async () => {
            const storedRoutes = JSON.parse(sessionStorage.getItem(sessionStorageStrings.ROUTES));
            if (storedRoutes) {
                setRoutes(storedRoutes);
            } else {
                const fetchedRoutes = await getRoutes();
                setRoutes(fetchedRoutes);
            }
        };

        loadRoutes();
    }, []);

    useEffect(() => {
        sessionStorage.setItem(sessionStorageStrings.OPEN_ROUTES, JSON.stringify(openRoutes));
    }, [openRoutes])

    const toggleSidebarCategory = useCallback((index) => {
        setOpenRoutes(prevOpenRoutes => ({
            ...prevOpenRoutes,
            [index]: !prevOpenRoutes[index]
        }));
    }, []);

    async function handleLogoutModal() {
        sessionStorage.removeItem(sessionStorageStrings.ROUTES);
        sessionStorage.removeItem(sessionStorageStrings.OPEN_ROUTES);
        sessionStorage.removeItem(sessionStorageStrings.DADOS_USUARIO_LOGADO);

        sessionStorage.setItem(sessionStorageStrings.USER_PATH_URL_BEFORE_LOGIN, pathLocation.pathname);

        history.push("/login");
    }

    return (
        <React.Fragment>
            <nav className={classNames("sidebar", sidebar.isOpen || "toggled", !sidebar.isOnRight || "sidebar-right")}>
                <div className="sidebar-content">
                    <div className="sidebar-brand">
                        <Col className="d-flex justify-content-center align-items-center">
                            <div style={{ cursor: "pointer", width: "100%" }} onClick={() => { history.push("/"); }}>
                                <img src={cliente_logo} alt="Logo" style={{ width: "100%", objectFit: "contain" }} />
                            </div>
                        </Col>
                    </div>

                    <ul
                        className="sidebar-nav">
                        {routes.map((route, index) => {
                            if (route.auth === 1) {
                                return (
                                    <React.Fragment key={index}>
                                        {route.header ? <li className="sidebar-header">{route.header}</li> : null}
                                        {route.children ? (
                                            <SidebarCategory
                                                name={route.name}
                                                badgeColor={route.badgeColor}
                                                badgeText={route.badgeText}
                                                icon={route.icon}
                                                to={route.path.split("/:")[0]}
                                                isOpen={openRoutes[index]}
                                                onClick={() => toggleSidebarCategory(index)}
                                            >
                                                {route.children.map((route, index) => {
                                                    if (route.auth === 1) {
                                                        return (
                                                            <SidebarItem
                                                                key={index}
                                                                id={route.id}
                                                                name={route.name}
                                                                to={route.path.split("/:")[0]}
                                                                badgeColor={route.badgeColor}
                                                                badgeText={route.badgeText}
                                                            />
                                                        );
                                                    }
                                                    return <></>;
                                                })}
                                            </SidebarCategory>
                                        ) : (
                                            <SidebarItem
                                                name={route.name}
                                                to={route.path.split("/:")[0]}
                                                icon={route.icon}
                                                badgeColor={route.badgeColor}
                                                badgeText={route.badgeText}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            }
                            return null;
                        })}
                    </ul>
                    <div className="footer">
                        <div className="sidebar-user">
                            <UncontrolledDropdown nav inNavbar className="ml-lg-1">
                                <DropdownToggle nav caret>
                                    <img
                                        src={dadosUsuarioLogado.url_foto ? dadosUsuarioLogado.url_foto : cliente_foto}
                                        alt="Foto"
                                        className="img-fluid rounded-circle mb-2"
                                        style={{ objectFit: "cover" }}
                                    />
                                </DropdownToggle>
                                <DropdownMenu right>

                                    <h6 className="card-subtitle text-muted"> Conta </h6>
                                    <DropdownItem onClick={() => setShowSenha(true)}>
                                        <div className="menu-user">
                                            <FontAwesomeIcon icon={faKey} /> Alterar Senha{" "}
                                        </div>
                                        <ModalAlterarSenha setShowSenha={setShowSenha} showSenha={showSenha} />
                                    </DropdownItem>

                                    <DropdownItem onClick={()=>{setShow(true)}}>
                                        <div className="menu-user">
                                            <FontAwesomeIcon icon={faSignOutAlt} /> Sair{" "}
                                        </div>
                                        <ModalConfirmacao
                                            mostrar={show}
                                            setMostrar={setShow}
                                            callbackSeSim={handleLogoutModal}
                                            mostrarBotaoNao
                                            textoTitulo={"Deseja realmente sair ?"}
                                        />
                                    </DropdownItem>

                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <div className="font-weight-bold"> {dadosUsuarioLogado.nome} </div>
                            <small> E-mail: {dadosUsuarioLogado.email}</small>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
};

export default withRouter(
    connect((store) => ({
        sidebar: store.sidebar,
    }))(Sidebar)
);
